import Navbar from "../../../components/layout/navbar/Navbar";
import image1 from "../../../assets/images/christina-wocintechchat-com-S3GrMiUhpNU-unsplash.jpg";
import "./user-profile.css";
import { MdSecurity } from "react-icons/md";
import { HiLightBulb } from "react-icons/hi";

function UserProfile() {
  return (
    <>
      <div className="user__profile">
        <div className="more_header">
          <h1>Profile</h1>
        </div>
        <div className="user__profile__body">
          <div className="more_div">
            <img src={image1} alt="profile" className="user__profile__img" />
            <div>
              <h1>Michael Oluwatosin</h1>
              <p>Email: michaelolu@gmail.com</p>
              <p>Phone number: +238037322299</p>
            </div>
          </div>
          <div className="more_div">
            <MdSecurity className="more_icons" />
            <div>
              <h1>Guarantor1</h1>
              <p>Name: Paul Alefade</p>
              <p>Phone number: +237157628222</p>
            </div>
          </div>
          <div className="more_div">
            <MdSecurity className="more_icons" />
            <div>
              <h1>Guarantor2</h1>
              <p>Name: Samuel Dennis</p>
              <p>Phone number: +239023522983</p>
            </div>
          </div>
          <div className="user__loan__body__info">
            <span>
              <p>
                Complete your tier 2 and tier 3 KYC form to get access to over
                #100,000,000 loan
              </p>
              <h3>Upgrade to access bigger laons</h3>
            </span>
            <HiLightBulb className="user__loan__body__info__icon" />
          </div>
        </div>
      </div>
      <Navbar number={4} />
    </>
  );
}

export default UserProfile;
