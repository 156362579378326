import "./not-authorized.css";

function NotAuthorized() {
  return (
    <div className="not__authorized">
      <h1>404 error! User nor authorized to view this page</h1>
    </div>
  );
}

export default NotAuthorized;
