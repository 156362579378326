import React from "react";
import { Outlet } from "react-router-dom";

function PublicRoute() {
  return (
    <main>
      <Outlet />
    </main>
  );
}

export default PublicRoute;
