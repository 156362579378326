import "./dashboard.css";
import Face from "../../../assets/images/christina-wocintechchat-com-S3GrMiUhpNU-unsplash.jpg";
import { MdOutlineAddCircleOutline } from "react-icons/md";
import { AiFillEye } from "react-icons/ai";
import { useState } from "react";
import Navbar from "../../../components/layout/navbar/Navbar";
import { useNavigate } from "react-router-dom";
import { HiLightBulb } from "react-icons/hi";
import { BiColor } from "react-icons/bi";
import { IoIosWarning } from "react-icons/io";

function Dashboard() {
  const navigate = useNavigate();

  const [show, setShow] = useState(false);

  return (
    <div className="dashboard">
      <div className="dashboard_body">
        <div className="dashboard_body_info">
          <img src={Face} alt="" className="dashboard_body_img" />
          <div className="dashboard_body_message">
            <h1>Greetings, Michael</h1>
            <p>Great loans at amazing rate!</p>
          </div>
        </div>

        <div className="scroll__bg">
          <div className="dashboard_body_account">
            <div className="dashboard_body_account_default">
              <h3>Loan Balance</h3>
              <div>
                <h1>{show ? "#423,890.03" : "***********"}</h1>
                <AiFillEye
                  className="changepassword_icon"
                  onClick={() => {
                    setShow(!show);
                  }}
                />
              </div>
            </div>
            <div className="dashboard_body_account_savings">
              <h3>Interest Rate</h3>
              <h1>3.5%</h1>
            </div>
          </div>
        </div>

        <div className="dashboard_body_add">
          <button className="dashboard_body_add_money">
            <MdOutlineAddCircleOutline className="dashboard_add_icons" />
            <h3>Repay</h3>
          </button>
          <button
            className="dashboard_body_add_modules"
            onClick={() => navigate("/user/apply")}
          >
            <h3>Apply for loan</h3>
          </button>
        </div>
        <div className="user__loan__body__info" style={{ width: "100%" }}>
          <span>
            <p>
              <b>Incomplete KYC profile:</b> Kindly complete your profile
              details to unlock the full access to BOI.
            </p>
            <h3 onClick={() => navigate("/user/kyc")}>Complete KYC</h3>
          </span>
          <IoIosWarning
            style={{ color: "lightred", fontSize: "25px" }}
            className="user__loan__body__info__icon"
          />
        </div>

        <div className="dashboard_body_transaction">
          <h1>Repayment history</h1>
          <div className="dashboard_body_transaction_statement">
            <button>Request for statement</button>
          </div>
          <div className="dashboard_body_transaction_history"></div>
        </div>
      </div>
      <Navbar number={1} />
    </div>
  );
}

export default Dashboard;
