import "./navbar.css";
import { useNavigate } from "react-router-dom";
import { BiHome } from "react-icons/bi";
import { BsFillPeopleFill } from "react-icons/bs";
import logo from "../../../assets/icons/OIP.jpeg";
import { VscSettingsGear } from "react-icons/vsc";
import { TbCurrencyNaira } from "react-icons/tb";

function Navbar({ number }) {
  const navigate = useNavigate();

  return (
    <div className="navbar">
      <img src={logo} alt="" className="navbar_four_logo" />
      <ul className="navbar_ul">
        <li
          onClick={() => {
            navigate("/dashboard");
          }}
          id={number === 1 ? "color1" : ""}
        >
          <BiHome className="navbar_icon" />
          <h1>Home</h1>
        </li>
        <li
          onClick={() => {
            navigate("/user/loan");
          }}
          id={number === 2 ? "color2" : ""}
        >
          <TbCurrencyNaira className="navbar_icon" />
          <h1>Loan</h1>
        </li>
        {/* <li
          onClick={() => {
            navigate("/dashboard/module");
          }}
          id={number === 3 ? "color3" : ""}
        >
          <MdLock className="navbar_icon" />
          <h1>Guarantor</h1>
        </li> */}
        <li
          onClick={() => {
            navigate("/user/profile");
          }}
          id={number === 4 ? "color4" : ""}
        >
          <BsFillPeopleFill className="navbar_icon" />
          <h1>Profile</h1>
        </li>
        <li
          onClick={() => {
            navigate("/user/more");
          }}
          id={number === 5 ? "color5" : ""}
        >
          <VscSettingsGear className="navbar_icon" />
          <h1>More</h1>
        </li>
      </ul>
      <button
        onClick={() => {
          navigate("/");
        }}
      >
        Log out
      </button>
    </div>
  );
}

export default Navbar;
