import { useState } from "react";
import "./signin.css";
import logo from "../../assets/icons/OIP.jpeg";
import { useNavigate } from "react-router-dom";
import { User } from "../../data/user";
import Toaster from "../../utils/toaster/Toaster";

function Signin() {
  const navigate = useNavigate();

  const [toaster, setToaster] = useState(false);
  const [message, setMessage] = useState("");
  const [bgcolor, setbgcolor] = useState("");

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    // Call backend API to authenticate user

    const authenticate = User.some(
      (user) => user.email === email && user.password === password
    );

    if (authenticate) {
      console.log("Authentication successful!");
      navigate("/dashboard");
    } else {
      console.log("Authentication failed!");
      setToaster(true);
      setbgcolor("#ff4d4d77");
      setMessage("Incorrect username or password");
      setTimeout(() => {
        setToaster(false);
      }, 3100);
    }
  };

  return (
    <div className="signin__main">
      {toaster ? <Toaster message={message} bgcolor={bgcolor} /> : <div></div>}

      <form className="signin__form" onSubmit={handleSubmit}>
        <img
          src={logo}
          alt="logo"
          className="signin__form__logo"
          onClick={() => navigate("/")}
        />
        <input
          type="email"
          placeholder="Email"
          name="email"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          required
        />
        <input
          type="password"
          placeholder="Password"
          name="password"
          onChange={(e) => setPassword(e.target.value)}
          value={password}
          required
        />
        <button type="submit">Sign in</button>
        <p>
          Don't have an account?{" "}
          <b onClick={() => navigate("/signup")}>Register</b>
        </p>
      </form>
    </div>
  );
}

export default Signin;
