export const User = [
  {
    id: 1,
    name: "John Doe",
    email: "johndoe@example.com",
    role: "admin",
    password: "rosario",
  },
  {
    id: 2,
    name: "Jane Smith",
    email: "abc@example.com",
    role: "user",
    password: "peace",
  },
];
