import React, { useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import './Toaster.css';

function Toaster({ message, bgcolor }) {
  const [show, setShow] = useState(true);

  setTimeout(() => {
    setShow(false);
  }, 10000);

  return (
    <div className={show ? 'toaster' : 'toaster slide'} style={{ backgroundColor: bgcolor }}>
      <div className="toaster_body">
        <p>{message}</p>
        <AiOutlineClose
          className="toaster_close"
          onClick={() => {
            setShow(false);
          }}
        />
      </div>
    </div>
  );
}

export default Toaster;
