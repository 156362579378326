import "./footer.css";
import logo from "../../../assets/icons/OIP.jpeg";
import { BiArrowToTop } from "react-icons/bi";
import { FaFacebook, FaLinkedin, FaTwitter } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa6";

function Footer() {
  const backToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className="footer">
      <div className="footer__header">
        <img src={logo} alt="logo" className="header__logo" />
        <span>
          <h1 onClick={backToTop}>
            Back to top <BiArrowToTop />
          </h1>
          <p>&copy; BOI 2024</p>
        </span>
      </div>
      <div className="footer__body">
        <ul className="footer__body__nav">
          <li>Home</li>
          <li>Who we are</li>
          <li>What we do</li>
          <li>Contact us</li>
        </ul>
        <span className="footer__body__socials">
          <FaFacebook />
          <FaTwitter />
          <FaInstagram />
          <FaLinkedin />
        </span>
      </div>
    </div>
  );
}

export default Footer;
