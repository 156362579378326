import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PrivateRoute from "./routes/PrivateRoute";
import PublicRoute from "./routes/PublicRoute";
import NotFound from "./common-pages/not-found/NotFound";
import ScrollToTop from "./utils/ScrollToTop";
import NotAuthorized from "./common-pages/not-authorized/NotAuthorized";
import AdminDashboard from "./admin/pages/admin-dashboard/AdminDashboard";
import LandingPage from "./landing-page/LandingPage";
import Signin from "./common-pages/signin/Signin";
import Signup from "./common-pages/signup/Signup";
import Dashboard from "./user/pages/dashboard/Dashboard";
import More from "./user/pages/more/More";
import UserLoan from "./user/pages/user-loan/UserLoan";
import UserProfile from "./user/pages/user-profile/UserProfile";
import Apply from "./user/pages/apply/Apply";
import SuccessApply from "./user/pages/success-apply/SuccessApply";
import KYC from "./user/pages/kyc/KYC";

const App = () => {
  const allowedRoles = ["Admin"];

  return (
    <Router>
      <ScrollToTop />
      <Routes>
        {/* Private route*/}
        <Route element={<PrivateRoute allowedRoles={allowedRoles} />}>
          <Route path="/admin" element={<AdminDashboard />} />
        </Route>

        {/* Public route*/}
        <Route path="/" element={<PublicRoute />}>
          <Route index element={<LandingPage />} />
          <Route path="/signin" element={<Signin />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/user/more" element={<More />} />
          <Route path="/user/loan" element={<UserLoan />} />
          <Route path="/user/profile" element={<UserProfile />} />
          <Route path="/user/apply" element={<Apply />} />
          <Route path="/user/kyc" element={<KYC />} />
          <Route
            path="/user/successful-application"
            element={<SuccessApply />}
          />
        </Route>

        {/* Unauthorized */}
        <Route path="/unauthorized" element={<NotAuthorized />} />

        {/* Catch all route */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};

export default App;
