import Footer from "../components/layout/footer/Footer";
import Header from "../components/layout/header/Header";
import "./landing-page.css";
import image1 from "../assets/images/iwaria-inc-M7ALc3UuX_g-unsplash.jpg";
import image2 from "../assets/images/christina-wocintechchat-com-S3GrMiUhpNU-unsplash.jpg";
import image3 from "../assets/images/iwaria-inc-tvTFMDwH-cQ-unsplash.jpg";
import {
  MdArrowOutward,
  MdOutlineKeyboardDoubleArrowRight,
} from "react-icons/md";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

function LandingPage() {
  const navigate = useNavigate();

  const [faq, setFaq] = useState({
    select1: true,
    select2: false,
    select3: false,
    select4: false,
  });

  const handleFaqClick = useCallback((option) => {
    setFaq((prevFaq) => ({
      ...prevFaq,
      [option]: !prevFaq[option],
    }));
  }, []);

  return (
    <div className="lp__main">
      <div className="lp">
        <Header />
        <section className="lp__section__one">
          <div className="lp__section__one__start">
            <h1>Quick and easy loans for your financial needs</h1>
            <p>
              Officia commodo quis cupidatat ex aliqua. Sit esse enim aute
              adipisicing aliquip quis consequat laborum in consectetur anim id
              adipisicing ipsum. Elit occaecat Lorem pariatur enim aliquip do
              laboris esse elit cupidatat mollit pariatur pariatur velit. Dolore
              adipisicing officia laborum consequat irure.
            </p>
            <button onClick={() => navigate("/signin")}>Apply for loan</button>
          </div>
          <img src={image1} alt="hero" className="lp__section__one__img" />
        </section>
        <section className="lp__section__two">
          <div className="lp__section__two__start">
            <h1>
              HOW IT WORKS <MdArrowOutward className={`lp__arrow`} />
            </h1>
            <div className="lp__section__two__start__block">
              <h1>
                <MdOutlineKeyboardDoubleArrowRight /> Application
              </h1>
              <p>
                Laborum id adipisicing occaecat et aliqua. Do dolor excepteur
                voluptate pariatur adipisicing ex proident anim pariatur amet
                dolor deserunt et quis.
              </p>
            </div>
            <div className="lp__section__two__start__block">
              <h1>
                <MdOutlineKeyboardDoubleArrowRight /> Documentation and
                Verification
              </h1>
              <p>
                Aute nostrud sunt aliquip ex Lorem ex occaecat dolore nulla
                cillum consectetur dolor ea cupidatat. Incididunt eiusmod
                commodo veniam anim labore adipisicing officia anim nulla
                aliquip ullamco.
              </p>
            </div>
            <div className="lp__section__two__start__block">
              <h1>
                <MdOutlineKeyboardDoubleArrowRight /> Credit Assesment
              </h1>
              <p>
                Sunt enim enim proident consequat in ullamco ad labore ex duis
                tempor dolore dolor. Do incididunt culpa esse sint. Minim aute
                voluptate proident dolore id eiusmod et labore Lorem in eiusmod
                duis consectetur. Velit velit eu magna consequat.
              </p>
            </div>
            <div className="lp__section__two__start__block">
              <h1>
                <MdOutlineKeyboardDoubleArrowRight /> Loan Approcal
              </h1>
              <p>
                Cillum ut cillum deserunt minim ea aute occaecat duis. Mollit
                nisi amet nostrud id ut ex ullamco tempor.
              </p>
            </div>
          </div>
          <div className="lp__section__two__end">
            <h1>BLOG</h1>
            <div className="lp__section__two__end__block">
              <img
                className="lp__section__two__end__block__img"
                alt="blog"
                src={image2}
              />
              <div className="lp__section__two__end__block__text">
                <p>
                  Irure incididunt dolor nisi sit nulla anim magna mollit ad
                  nulla dolore. Nisi pariatur enim sit laboris. Proident veniam
                  incididunt enim cillum commodo eiusmod. Cillum esse minim
                  commodo nisi irure veniam.
                </p>
                <h3>View post</h3>
              </div>
            </div>
            <div className="lp__section__two__end__block">
              <img
                className="lp__section__two__end__block__img"
                alt="blog"
                src={image3}
              />
              <div className="lp__section__two__end__block__text">
                <p>
                  Irure incididunt dolor nisi sit nulla anim magna mollit ad
                  nulla dolore. Nisi pariatur enim sit laboris. Proident veniam
                  incididunt enim cillum commodo eiusmod. Cillum esse minim
                  commodo nisi irure veniam.
                </p>
                <h3>View post</h3>
              </div>
            </div>
          </div>
        </section>
        <section className="lp__section__three">
          <span className="lp__section__three__start">
            <h3>FAQ</h3>
            <h1>
              Do you have another questions about BOI? Explore our Frequently
              Asked questions
            </h1>
          </span>
          <div>
            <div className="lp__faq">
              <div
                className="lp__faq__question"
                onClick={() => handleFaqClick("select1")}
              >
                <h1>01</h1>
                <span>
                  WHAT IS BOI ALL ABOUT?{" "}
                  <MdArrowOutward
                    className={`lp__arrow${faq.select1 ? " open" : ""}`}
                  />
                </span>
              </div>
              <div className={`lp__faq__answer${faq.select1 ? " open" : ""}`}>
                <p>
                  Quis mollit consequat cupidatat in Lorem anim amet culpa
                  voluptate cillum. Et commodo mollit aliqua laboris cupidatat
                  duis consequat non pariatur labore. Ex ad sunt fugiat Lorem
                  consequat esse cillum. Elit laboris tempor consectetur nulla
                  consectetur amet exercitation cillum mollit exercitation elit
                  aute veniam dolor.
                </p>
              </div>
            </div>
            <div className="lp__faq">
              <div
                className="lp__faq__question"
                onClick={() => handleFaqClick("select2")}
              >
                <h1>02</h1>
                <span>
                  REQUIREMENTS TO ACCESS LOANS?{" "}
                  <MdArrowOutward
                    className={`lp__arrow${faq.select2 ? " open" : ""}`}
                  />
                </span>
              </div>
              <div className={`lp__faq__answer${faq.select2 ? " open" : ""}`}>
                <p>
                  Quis mollit consequat cupidatat in Lorem anim amet culpa
                  voluptate cillum. Et commodo mollit aliqua laboris cupidatat
                  duis consequat non pariatur labore. Ex ad sunt fugiat Lorem
                  consequat esse cillum. Elit laboris tempor consectetur nulla
                  consectetur amet exercitation cillum mollit exercitation elit
                  aute veniam dolor.
                </p>
              </div>
            </div>
            <div className="lp__faq">
              <div
                className="lp__faq__question"
                onClick={() => handleFaqClick("select3")}
              >
                <h1>03</h1>
                <span>
                  DURATION OF PAYMENT?{" "}
                  <MdArrowOutward
                    className={`lp__arrow${faq.select3 ? " open" : ""}`}
                  />
                </span>
              </div>
              <div className={`lp__faq__answer${faq.select3 ? " open" : ""}`}>
                <p>
                  Quis mollit consequat cupidatat in Lorem anim amet culpa
                  voluptate cillum. Et commodo mollit aliqua laboris cupidatat
                  duis consequat non pariatur labore. Ex ad sunt fugiat Lorem
                  consequat esse cillum. Elit laboris tempor consectetur nulla
                  consectetur amet exercitation cillum mollit exercitation elit
                  aute veniam dolor.
                </p>
              </div>
            </div>
            <div className="lp__faq">
              <div
                className="lp__faq__question"
                onClick={() => handleFaqClick("select4")}
              >
                <h1>04</h1>
                <span>
                  WHAT IS THE INTEREST RATE?{" "}
                  <MdArrowOutward
                    className={`lp__arrow${faq.select4 ? " open" : ""}`}
                  />
                </span>
              </div>
              <div className={`lp__faq__answer${faq.select4 ? " open" : ""}`}>
                <p>
                  Quis mollit consequat cupidatat in Lorem anim amet culpa
                  voluptate cillum. Et commodo mollit aliqua laboris cupidatat
                  duis consequat non pariatur labore. Ex ad sunt fugiat Lorem
                  consequat esse cillum. Elit laboris tempor consectetur nulla
                  consectetur amet exercitation cillum mollit exercitation elit
                  aute veniam dolor.
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
}

export default LandingPage;
