import { useNavigate } from "react-router-dom";
import Navbar from "../../../components/layout/navbar/Navbar";
import image1 from "../../../assets/icons/73261847_JEMA GER 1662-02.webp";
import { HiLightBulb } from "react-icons/hi";

function SuccessApply() {
  const navigate = useNavigate();
  return (
    <>
      <div className="user__loan">
        <div className="more_header"></div>
        <div className="user__loan__body">
          <img src={image1} alt="money" />
          <h1>Successful application!</h1>
          <p>You will receive a email when your application is approved</p>
          <div className="user__loan__body__info">
            <span>
              <p>
                While waiting for the feedback email, upgrade your tier by
                completing you KYC
              </p>
              <h3>Get started</h3>
            </span>
            <HiLightBulb className="user__loan__body__info__icon" />
          </div>
          <button onClick={() => navigate("/dashboard")}>Go Back Home</button>
        </div>
      </div>
      <Navbar />
    </>
  );
}

export default SuccessApply;
