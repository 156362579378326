import { Navigate, Outlet } from "react-router-dom";
import useAuth from "../hooks/useAuth";

function PrivateRoute({ allowedRoles }) {
  const { auth } = useAuth();

  if (!auth || !auth.user) {
    // If auth or auth.user is null, redirect to login or some other appropriate page
    return <Navigate to="/login" />;
  }

  return allowedRoles.includes(auth.user.role) ? (
    <Outlet />
  ) : (
    <Navigate to="/unauthorized" />
  );
}

export default PrivateRoute;
